import React from 'react' ;
import html2pdf from 'html2pdf.js';
import EmbedFile from '../../embed/EmbedFile' ;
import { connect } from 'react-redux';
import { AnswerMultimediaPlayer } from '../../embed/AnswerMultimediaPlayer';
import {  ACCEPTED_MULTIMEDIA_FORMATS} from '../../../actions/constants';
import { current_tenant } from '../../../actions/tenantActions';
/**
 * Componente para las respuestas en estado Cerrado
 * Muestras la matriz de codificación de la respuesta con los criterios seleccionados por el codificador
 *
 * @class AnswerClosed
 * @extends {Component}
 */
const AnswerClosed = (props) => {
  const {answer} = props;
  const downloadAsPdf = () => {
    const report = document.getElementById('report');
    const options = { margin: [15, 10, 15, 10],// top, left, bottom, right
                      filename: 'reporte.pdf',
                      image: { type: 'png', quality: 2 },
                      html2canvas: { logging: true },
                      jsPDF: { unit: 'mm', format: 'a4', orientation: 'p'} };

    html2pdf().from(report).set(options).toPdf().save();
  }
  const isMultimediaFile = (file) => {
    let isMmultimedia = false;
    try {
      var format = file.split(".")
    }
    catch (error) {
      console.log("No es formato valido")
    }
    if (ACCEPTED_MULTIMEDIA_FORMATS.includes(format[format.length -1])){
        isMmultimedia = true;
    }
    return isMmultimedia;
  }

  const renderGrades = () => {
    const { answer } = props;
    
    if (answer.graded) {
      if (answer.privateGrades){
        return null
      }
      return (
        <div id={"grades"}>
          <div>
              <h2>Codificación</h2>
              <div>
                {current_tenant !== 'ecentroevaluacion' ? (
                  <>
                    <p>
                      La escritura es una herramienta fundamental en el contexto académico y profesional.
                      El Centro de Español le ofrece, de manera gratuita, acompañamiento para desarrollar
                      y fortalecer sus habilidades comunicativas, con el fin de enfrentar exitosamente los
                      retos académicos durante sus estudios en la Universidad de los Andes, así como en su
                      vida profesional.
                    </p>
                    <p>
                      A continuación, encontrará un reporte que muestra un diagnóstico de sus habilidades de
                      escritura. En este usted encontrará la descripción de su nivel de desempeño actual en
                      la construcción de oraciones y de párrafos; en la presentación coherente de sus ideas
                      en el texto; en el uso de la ortografía y el lenguaje académico; en el reconocimiento
                      de la propiedad intelectual; en el uso de fuentes para argumentar y en la construcción
                      tanto de una postura como de argumentos que la sustentan. Del mismo modo, usted hallará
                      el desempeño excelente con respecto a estos criterios.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                    A continuación se presenta la codificación que hicimos de su respuesta. Para ello,
                     dos personas la revisaron de forma independiente y le asignaron un nivel de acuerdo con
                      la rúbrica. En caso de que se presentaran discrepancias entre ellas, una tercera persona
                      hizo una revisión adicional y se comunicó con las anteriores para llegar a un acuerdo.
                    </p>
                    <p>
                      Para cada uno de los tres criterios de la rúbrica se indica el nivel al que fue asignada
                       su respuesta como resultado de ese proceso. Adicionalmente, para cada criterio se presenta, a manera de referencia, la descripción del nivel más alto que se podía alcanzar.
                    </p>
                  </>
                )}
              </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Criterio</th>
                  <th>Nivel</th>
                  <th>Desempeño actual</th>
                  <th>Desempeño más alto</th>                  
                </tr>
              </thead>
              <tbody>
                {answer.grades.map((grade) => {
                  return (
                    <tr key={grade.id}>
                      <td>
                        <strong>{grade.gradingCriterion.title}</strong>
                        <br />
                        <small dangerouslySetInnerHTML={{ __html: grade.gradingCriterion.description == grade.gradingCriterion.title ? '' : grade.gradingCriterion.description }}></small>
                      </td>
                      <td>
                        <strong><small dangerouslySetInnerHTML={{ __html: grade.gradingCriterionOption.gradingLevel.toUpperCase() }}></small></strong>
                      </td>
                      <td dangerouslySetInnerHTML={{ __html: grade.gradingCriterionOption.feedback }}>
                      </td>
                      <td dangerouslySetInnerHTML={{ __html: grade.gradingCriterion.highestGradingOption.feedback }}>
                      </td>
                     
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
          {current_tenant != 'ecentroevaluacion' ? ( <>
            <p>
              Para recibir retroalimentación personalizada acerca de este reporte
              por parte de un tutor del Centro, pida una cita a través del portal web del <a
                href="https://centrodeespanol.uniandes.edu.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Centro de Español
              </a>.
            </p>
            <p>
              En esta tutoría usted podrá interactuar con un tutor a partir de su texto para desarrollar
              habilidades que le permitan pensar críticamente y usar la escritura como medio para comunicar
              sus ideas. Además, usted también podrá desarrollar sus habilidades de lectura y de comunicación
              oral para distintos ejercicios académicos en este tipo de interacciones con un(a) tutor(a).
            </p>
          </>) : ""}
      </div>
        </div>
      );
    } else if ( (answer.answer===null && answer.status === "to_do")|| (answer.answer===null && answer.status === "in_progress" ) ) {
      return <br></br>
    } else {
      return <h3>Tu respuesta aún no ha sido codificada</h3>
    }
  }
  const getTextFromAnswer = () => {
    if (props.answer.answer!==null && props.answer.answer!=='null'){
        return (<div dangerouslySetInnerHTML={{__html: props.answer.answer}}/>)
    } else {
        return (<p>Esta respuesta no tiene ningún texto</p>)
    }
  }
  const renderAnswer = () => {
    const { answer } = props;
    if ( (answer.answer===null && answer.status === "to_do")|| (answer.answer===null && answer.status === "in_progress" ) ){
      return <div >No enviaste respuesta alguna.</div>;
    } else if (answer) {
      return (
        <div>
          
          { getTextFromAnswer()}
          {answer.attachment && isMultimediaFile(answer.attachment) ?
            <AnswerMultimediaPlayer 
            forAnswers={true}
            id={answer.id} filename={answer.attachment}
            url={props.url}
            onReloadVideo={props.onReloadVideo}/>: 
            <EmbedFile answer={answer}/> }   
        </div>
      )
    } else {
      return <div>No hay ningun texto</div>;
    }
  }


    return (
      <div >
        <div className="" id="report">
          <div className="col-md-12 col-xs-12">
            <h2>Mi respuesta</h2>
            {renderAnswer()}
          </div>
          <div className="col-md-12 col-xs-12">
            {renderGrades()}
          </div>
        </div>
      </div>
    )
}

export default connect(null, {})(AnswerClosed);
