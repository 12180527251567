import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de usuarios en el estado.

// Actualiza los usuarios en el estado.
export const GET_USERS = 'GET_USERS';

// Actualiza el usuario actual en el estado.
export const GET_USER = 'GET_USER';

// Actualiza los estudiantes en el estado.
export const GET_STUDENTS = 'GET_STUDENTS';

// Actualiza los codificadores en el estado.
export const GET_GRADERS = 'GET_GRADERS';

// Actualiza los revisores en el estado.
export const GET_REVIEWERS = 'GET_REVIEWERS';

// Actualiza los administradores en el estado.
export const GET_ADMINS = 'GET_ADMINS';

// Remueve un usuario del estado.
export const DELETE_USER = 'DELETE_USER';

//Agrega roles al usuario
export const ADD_USER_ROLES = 'ADD_USER_ROLES';


export const GET_USERSVIDEOS = 'GET_USERSVIDEOS';

export const GET_MESSAGE_NEWUSER = 'GET_MESSAGE_NEWUSER';

export const SEARCH_USERSVIDEOS = 'SEARCH_USERSVIDEOS';

export const UPDATE_USER = 'UPDATE_USER';



/**
 * Obtiene los usuarios de la aplicación.
 *
 * @export getUsers
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getUsers(pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/users${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_USERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los usuarios con rol codificador.
 *
 * @export getGraders
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getGraders(successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/users/graders`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * editar un usuario
 *
 * @export updateUser
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateUser(id, user,  successCallback = () => {}, errorCallback = () => {}) {

  const request = axios.patch( `${ROOT_URL}/users/${id}`, { user: user }, getAuthHeaders());

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_MESSAGE_NEWUSER,
          payload: data,
        });
        successCallback();
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          console.log(error);
        }
        errorCallback(error);

      });
  };
}

/**
 * Obtiene los usuarios con rol revisor.
 *
 * @export getReviewers
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getReviewers(successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/users/reviewers`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_REVIEWERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

// Sin uso por ahora

/**
 * Obtiene los usuarios con rol estudiante.
 *
 * @export getStudents
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getStudents(successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/users/students`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_STUDENTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

// Sin uso por ahora
/**
 * Obtiene los usuarios con rol administrador
 *
 * @export getAdmins
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAdmins(successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/users/admins`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ADMINS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea un usuario en el sistema con los datos dados en el parámetro user.
 *
 * @export createUser
 * @param {*} user : Información del nuevo usuario a crear
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createUser(user, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.post(`${ROOT_URL}/users`, {user: user}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_USER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log("entrando:", error)
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el resultado de la operacion de asociar docentes.
 *
 * @export createUserFromExcel
 
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function createUserFromExcel( values, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/users/create_users_from_excel`, values, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MESSAGE_NEWUSER,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Carga usuarios de manera masiva desde un archivo Excel.
 *
 * @export createUsersBatchFromExcel
 * @param {*} file : Archivo de Excel a cargar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createUsersBatchFromExcel(file, successCallback = () => {}, errorCallback = (error) => {}) {
  const formData = new FormData();
  formData.append('users_list', file);

  const request = axios.patch(`${ROOT_URL}/users/create_users_batch_from_excel`, formData, {
    headers: {
      ...getAuthHeaders().headers,
      'Content-Type': 'multipart/form-data',
    },
  });

  return (dispatch) => {
    request
      .then(({ data }) => {
        dispatch({
          type: GET_MESSAGE_NEWUSER,
          payload: data,
        });
        successCallback(data);
      })
      .catch((error) => {
        if (!isUnauthorizedError(error, dispatch)) {
          // Extraer el mensaje del error desde el backend.
          const errorMessage = error.response?.data?.message || 'Hubo un problema al subir el archivo. Por favor, inténtalo de nuevo.';
          errorCallback(errorMessage);
        }
      });
  };
}




/**
 * Eliminar un usuario según el id dado por parámetro.
 *
 * @export deleteUser
 * @param {*} id : EL id del usuario.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteUser(id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.delete(`${ROOT_URL}/users/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(() => {
      dispatch({
        type: DELETE_USER,
        payload: id
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene el usuario solicitado.
 *
 * @export getUser
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getUser(id, successCallback = ()=>{}) {
  const request = axios.get(`${ROOT_URL}/users/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_USER,
        payload: data
      });
      successCallback(data);
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the user', error);
      }
    });
  }
}

/** 
 * Agrega roles al usuario seleccionado.
 *
 * @export editUserRoles
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
*/
export function editUserRoles(id, roles,successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.patch(`${ROOT_URL}/users/${id}/add_roles`, {roles: roles}, getAuthHeaders());
  return (dispatch) => {
    request.then((data) => {
      dispatch({
        type: ADD_USER_ROLES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/** 
 * Agrega roles al usuario seleccionado.
 *
 * @export editUserVideosRoles
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
*/
export function editUserVideosRoles(id, roles,successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.patch(`${ROOT_URL}/users/${id}/change_roles`, {roles: roles}, getAuthHeaders());
  return (dispatch) => {
    request.then((data) => {
      dispatch({
        type: ADD_USER_ROLES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/** 
 * Agrega roles al usuario seleccionado.
 *
 * @export addRolesByEmail
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
*/
export function addRolesByEmail(email, roles,successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.post(`${ROOT_URL}/users/add_roles_by_email`, { email ,roles: roles}, getAuthHeaders());
  return (dispatch) => {
    request.then((data) => {
      dispatch({
        type: ADD_USER_ROLES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene los usuarios del módulo de videos.
 *
 * @export getusersVideos
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getusersVideos({pagination = '', search = ''}, successCallback = () => {}, errorCallback = (error) => {}) {
  const searchQuery = search ? `&search%5Bquery%5D=${search.query}&search%5Bvalue%5D=${search.value}` : ''
  const request = axios.get(`${ROOT_URL}/users/usersVideos${pagination}${searchQuery}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_USERSVIDEOS,
        payload: search ? {...data, search} : data
      });
      successCallback();
    }).catch((error) => {
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

export function searchUser(pagination, text = '', successCallback = () => {}, errorCallback = (error) => {}) {
  const url = `${pagination}&%5Bsearch%5D=${encodeURIComponent(text)}`;
  const request = axios.get(`${ROOT_URL}/users/usersVideos${url}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: SEARCH_USERSVIDEOS,
        payload: {...data, text}
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if (!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}



