import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useSelector , useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { LOG_IN_URL_OUTLOOK } from '../actions/constants';
import { LOG_IN_URL_GMAIL } from '../actions/constants';
import { LOG_IN_URL_YAHOO } from '../actions/constants';
import { checkBackendStatus } from '../actions/constants';
import { STRUCTURE_DOC, SUPPORT_TEACHER_DOC, SUPPORT_ADMIN_DOC, SUPPORT_GRADER_DOC, SUPPORT_STUDENT_DOC, SUPPORT_REVIEWER_DOC, OBSERVER_ADMIN_DOC, OBSERVER_DOC } from '../actions/constants';
import { toggleUserRole, resetUserRole } from '../actions/authenticationActions';
import { unbecome } from '../actions/authenticationActions';

/**
 * Componente Reacondicionado con React Hooks !! por w.garcia24 
 * 
 * Componente barra de navegacion de la aplicacion.
 */

import NavItem from './workaround/NavItem';

const ICONOS_DESARROLLO = process.env.NODE_ENV === 'development'

const NavbarNew = () => {
  // Use the useSelector hook to access the currentUser and currentTenant from the Redux store
  const currentUser   = useSelector(state => state.currentUser);
  const currentTenant = useSelector(state => state.tenant.tenant);
  const dispatch      = useDispatch()

  const [displayMenu, setDisplayMenu] = useState('NEW');

  const document_icon = 'fa fa-file-pdf-o text-danger fa-lg'
  const image_icon    = 'fa fa-file-image-o text-success fa-lg'

  const signin_icon   = 'fa fa-sign-in fa-lg'
  const signout_icon  = 'fa fa-sign-out fa-lg'
  const user_icon     = "fa fa-user-circle-o fa-lg"

  const done_icon     = "fa fa-check-circle-o text-success"
  const todo_icon     = "fa fa-cogs text-danger"
  const help_icon     = "fa fa-question-circle text-info fa-lg"

  const not_icon      = "fa fa-times-circle-o text-danger"

  const rocket_icon   = 'fa fa-rocket text-success'

  const renderIcon = (icon) => {
    if (! ICONOS_DESARROLLO) return (null)
    return (renderIconAll(icon))
  }

  const renderIconAll = (icon) => {
    return (<i className={icon} />)
  }

 // Estado para almacenar el estado del backend y si mostrar el mensaje de advertencia
 const [backendStatus, setBackendStatus] = useState('Verificando...');
 const [isBackendOffline, setIsBackendOffline] = useState(false);

 // useEffect para verificar el estado del backend al cargar la navbar
 useEffect(() => {
   const verifyBackend = async () => {
     const status = await checkBackendStatus();
     if (status === 'online') {
       setBackendStatus('🟢 Online');
       setIsBackendOffline(false);
     } else {
       setBackendStatus('🔴 Offline');
       setIsBackendOffline(true);
     }
   };

   verifyBackend();
 }, []);


  const renderLoginLogout = () => {
    if (currentUser) {
      const manuales = [
        {title: 'Estructura del Front', action: STRUCTURE_DOC, auth: (currentUser && currentUser.isAdmin || null), icon : image_icon ,divider : true },
        {title: 'Administrador', action: SUPPORT_ADMIN_DOC, auth: (currentUser && currentUser.isAdmin || null), icon : document_icon},
        {title: 'Docente', action: SUPPORT_TEACHER_DOC, auth: (currentUser && (currentUser.isTeacher || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Codificador', action: SUPPORT_GRADER_DOC, auth: (currentUser && (currentUser.isGrader || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Estudiante', action: SUPPORT_STUDENT_DOC, auth: (currentUser && (currentUser.isStudent || currentUser.isAdmin )|| null), icon : document_icon},
        {title: 'Revisor', action: SUPPORT_REVIEWER_DOC, auth: (currentUser && (currentUser.isReviewer || currentUser.isAdmin ) || null), icon : document_icon},
        {title: 'Gestor Procesos de Observación', action: OBSERVER_ADMIN_DOC, auth: (currentUser && currentUser.isObserverAdmin || null), icon : document_icon},
        {title: 'Observador', action: OBSERVER_DOC, auth: (currentUser && currentUser.isObserver || null), icon : document_icon}
      ]
      const help_title = <>{renderIconAll(help_icon)} Manuales</>
      const user_title = <>{renderIconAll(user_icon)} {currentUser.name? currentUser.name:'Bienvenid@' }</>
      // console.log(`currentUser ${JSON.stringify(currentUser,null,4)}`)
      return (
        <>
        <NavDropdown eventKey={29} title={user_title} id="basic-nav-dropdown">
          <MenuItem disabled>{currentUser.email}</MenuItem>
          <MenuItem divider></MenuItem>
          <NavDropdown eventKey={30} title={help_title} id="basic-nav-dropdown">
            {currentUser && manuales.map((manual, i) => (
                manual.auth ? <>
                  <MenuItem href={manual.action} key={(i+1)} target="_blank" eventKey={31+i}> 
                    {manual.icon ? <>{renderIconAll(manual.icon)} </> : ''}
                    Manual de {manual.title}
                  </MenuItem>
                  {manual.divider ? <MenuItem key={`d_${i}`}></MenuItem> : ''}
                </> : ''
              )
            )}
          </NavDropdown>
          <MenuItem divider></MenuItem>
          <LinkContainer to="/users/logout" eventKey={1}>
            <NavItem> 
              {renderIconAll(signout_icon)} Cerrar sesión
            </NavItem>
          </LinkContainer>
        </NavDropdown> 
        </>
      );
    } else {
      const title = <>{renderIconAll(signin_icon)} Ingresar</>
      return (
        <NavDropdown eventKey={2} title={title} id="basic-nav-dropdown">
            <MenuItem href={LOG_IN_URL_OUTLOOK} eventKey={3}><i className="fa fa-windows text-primary" /> Outlook(Uniandes)</MenuItem>
            <MenuItem href={LOG_IN_URL_GMAIL}   eventKey={4}><i className="fa fa-google text-danger" /> Gmail</MenuItem>
            <MenuItem href={LOG_IN_URL_YAHOO}   eventKey={5}><i className="fa fa-yahoo text-danger" /> Yahoo</MenuItem>
      </NavDropdown>
      );
    }
  }

  const renderTeacherNavItems= () => {
    if (currentUser && currentUser.isTeacher) {
      const title = <>Cursos</>
      return (
        <NavDropdown eventKey={6} title={title} id="basic-nav-dropdown">
          <LinkContainer to="/coursesTeacher" eventKey={7}>
            <MenuItem>
              {renderIcon(todo_icon)} Ver mis cursos
            </MenuItem>
          </LinkContainer>
          <MenuItem divider></MenuItem>
          <MenuItem href={SUPPORT_TEACHER_DOC}  target="_blank" eventKey={8}> <i className={document_icon} /> Manual de Docente</MenuItem>
        </NavDropdown> 
      );
    } else {
      return (null);
    }
  }

  const renderStudentNavItems = () => {
    if (currentUser && currentUser.isStudent) {
      return (<>
        <LinkContainer to="/answers" eventKey={9}>
          <NavItem>
            {renderIcon(todo_icon)} Mis pruebas
          </NavItem>
        </LinkContainer>
        <MenuItem divider></MenuItem>
        <MenuItem href={SUPPORT_STUDENT_DOC}  target="_blank" eventKey={8}> <i className={document_icon} /> Manual de Estudiante</MenuItem>
      </>);
    } else {
      return (null);
    }
  }

  const renderGraderNavItems = () => {
    if (currentUser && currentUser.isGrader) {
      return (<>
        <LinkContainer to="/answerGraders" eventKey={10}>
          <NavItem>
            {renderIcon(todo_icon)} Centro de codificación
          </NavItem>
        </LinkContainer>
        {/* <MenuItem divider></MenuItem> */}
      </>);
    } else {
      return (null);
    }
  }

  const renderReviewerNavItems = () => {
    if (currentUser && currentUser.isReviewer) {
      return (<>
        <LinkContainer to="/answerReviewers" eventKey={11}>
          <NavItem>
            {renderIcon(todo_icon)} Centro de revisión
          </NavItem>
        </LinkContainer>
      </>      );
    } else {
      return (false);
    }
  }

  const renderManualesCalificacion = () => {
    if (currentUser) {
      return (<>
      {currentUser.isReviewer && 
       <MenuItem href={SUPPORT_REVIEWER_DOC}  target="_blank" eventKey={2.1}> <i className={document_icon} /> Manual de Revisor</MenuItem>
      }
      {currentUser.isGrader &&
        <MenuItem href={SUPPORT_GRADER_DOC}  target="_blank" eventKey={2.2}> <i className={document_icon} /> Manual de Codificador</MenuItem>
      }
      {currentUser.isObserver &&
        <MenuItem href={OBSERVER_DOC}  target="_blank" eventKey={2.3}> <i className={document_icon} /> Manual de Observador</MenuItem>
      }
      </>      );
    } else {
      return (false);
    }    
  }

  const isAdmin = ()=>{
    if (currentUser.isAdmin || currentUser.isExamAdmin){
      return true
    }
    return false
  }
  
  const renderAdminNavItems = () => {
    if (currentUser && isAdmin())  {
      const title = <>{renderIcon(todo_icon)} Panel de aplicación</>
      return (
        <NavDropdown eventKey={12} title={title} id="basic-nav-dropdown">
            <LinkContainer to="/exams" eventKey={16}>
              <MenuItem>{renderIcon(todo_icon)}Examenes</MenuItem>
            </LinkContainer>
            <LinkContainer to="/tests" eventKey={15}>
              <MenuItem >{renderIcon(todo_icon)} Preguntas</MenuItem>
            </LinkContainer>
            <LinkContainer to="/texts" eventKey={14}>
              <MenuItem >{renderIcon(todo_icon)} Contenidos Adjuntos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/questionaries" eventKey={18}>
              <MenuItem>{renderIcon(todo_icon)} Cuestionarios</MenuItem>
            </LinkContainer>
            <MenuItem divider></MenuItem>
            {currentUser.isAdmin?
              <LinkContainer to="/superMatrices" eventKey={22}>
                <MenuItem>{renderIcon(todo_icon)} Matrices de reporte histórico</MenuItem>
              </LinkContainer>:<></>
            }
            <LinkContainer to="/gradingMatrices" eventKey={13}>
              <MenuItem >{renderIcon(todo_icon)} Matrices de codificación</MenuItem>
            </LinkContainer>
            <LinkContainer to="/answerBank" eventKey={21}>
              <MenuItem>{renderIcon(todo_icon)} Banco de respuestas</MenuItem>
            </LinkContainer>
            {currentTenant === 'ecentroevaluacion' && (<>
              <MenuItem divider></MenuItem>
              <LinkContainer to="/labels" eventKey={17}>
                <MenuItem >{renderIcon(todo_icon)} Etiquetas</MenuItem>
              </LinkContainer>
              <LinkContainer to="/themes" eventKey={19}>
                <MenuItem>{renderIcon(todo_icon)} Temas</MenuItem>
              </LinkContainer>
            </>)}
            <MenuItem divider></MenuItem>
            <LinkContainer to="/users" eventKey={17}>
              <MenuItem >{renderIcon(done_icon)} Usuarios</MenuItem>
            </LinkContainer>
            <LinkContainer to="/students" eventKey={19}>
              <MenuItem>{renderIcon(done_icon)} Consultar estudiantes</MenuItem>
            </LinkContainer>
            <MenuItem divider></MenuItem>
            <LinkContainer to="/coursesAdmin" eventKey={20}>
              <MenuItem>{renderIcon(todo_icon)} Administración de cursos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/semesterYear" eventKey={23}>
              <MenuItem>{renderIcon(todo_icon)} Semestres</MenuItem> 
            </LinkContainer>
        </NavDropdown> 
      );
    } else {
      return (null);
    }
  }
  
  const renderReportNavItems = () => {
    if (currentUser && isAdmin()) {
      const title = <>{renderIcon(todo_icon)} Reportes agregados</>
      return (
        <NavDropdown eventKey={24}  title={title} id="basic-nav-dropdown">
          <LinkContainer to="/faculties" eventKey={25}>
            <MenuItem>Facultad</MenuItem>
          </LinkContainer>
          <LinkContainer to="/departments" eventKey={26}>
            <MenuItem>Departamento</MenuItem>
          </LinkContainer>
          <LinkContainer to="/courses" eventKey={27}>
            <MenuItem>Curso</MenuItem>
          </LinkContainer>
          <LinkContainer to="/answersReport" eventKey={28}>
            <MenuItem>Respuestas</MenuItem>
          </LinkContainer>
        </NavDropdown>
      );
    } else {
      return (null);
    }
  }

  const renderObserverModuleNavItems = () => {
    if (currentTenant === "ecentroevaluacion" && currentUser && (currentUser.isObserverAdmin || currentUser.isObserver)) {
      return ( 
        <>
          {currentUser.isObserver &&
            (<>
              <LinkContainer to="/observers/processes" eventKey={28}>
                <NavItem>
                  {renderIcon(done_icon)} Centro de observación
                </NavItem>
              </LinkContainer>
            </>)
          } 
          
        </> 
      );
    } else {
      return (null);
    }
  }

  const renderObserverAdminModuleNavItems = () => {
    if (currentTenant === "ecentroevaluacion" && currentUser && (currentUser.isObserverAdmin || currentUser.isObserver)) {
      const title = <>{renderIcon(done_icon)} Panel de Observación</>
      return ( 
        <>
          {currentUser.isObserverAdmin && (
          <NavDropdown eventKey={24}  title={title} id="basic-nav-dropdown">
            <LinkContainer to="/processes" eventKey={25}>
              <MenuItem>{renderIcon(done_icon)} Procesos</MenuItem>
            </LinkContainer>
            <LinkContainer to="/usersVideos" eventKey={27}>
              <MenuItem>{renderIcon(done_icon)} Usuarios</MenuItem>
            </LinkContainer>
            <LinkContainer to="/videos" eventKey={26}>
              <MenuItem>{renderIcon(done_icon)} Videos</MenuItem>
            </LinkContainer>
          </NavDropdown>)}
        </> 
      );
    } else {
      return (null);
    }
  }


  const renderRoleItem = (role, text) =>{
    const value = currentUser[role]
    return (
      <MenuItem onClick={() => onRoleClick(role)}>
        {value ? <>{renderIcon(done_icon)} {text}</> : <>{renderIcon(not_icon)} {text}</> }
      </MenuItem>
    )
  }

  const onRoleClick = (role) => {
    dispatch(toggleUserRole(role))
  }

  const onResetRoleClick = () => {
    dispatch(resetUserRole())
  }


  const renderPreferedRoles = () =>{
    if (currentUser && isAdmin()) {
      const title = <>{renderIcon(rocket_icon)} Prefered Roles</>
      return (
        <NavDropdown eventKey={24}  title={title} id="basic-nav-dropdown">
          {renderRoleItem('isExamAdmin','exa-admin')}
          {renderRoleItem('isObserverAdmin','obs-admin')}
          <MenuItem divider></MenuItem>
          {renderRoleItem('isStudent','estudiante')}
          {renderRoleItem('isTeacher','profesor')}
          <MenuItem divider></MenuItem>
          {renderRoleItem('isGrader','codificador')}
          {renderRoleItem('isReviewer','revisor')}
          {renderRoleItem('isObserver','observador')}
        </NavDropdown>
      );
    } else {
      return (null);
    }
  }


  const renderNavbarNew = () => {
    return (
      <BootstrapNavbar collapseOnSelect fluid>
        <BootstrapNavbar.Header>
          <BootstrapNavbar.Brand>
              <Link to="/">
                <img
                  src={`/images/${currentTenant}/LogoAndes.svg`}
                  alt="Universidad de los Andes"
                  width="165px"
                />
              </Link>
          </BootstrapNavbar.Brand>
          <BootstrapNavbar.Toggle />
        </BootstrapNavbar.Header>
        <BootstrapNavbar.Collapse>
          <Nav>
            {currentUser && (currentUser.isStudent) &&
              <NavDropdown eventKey={24}  title='Pruebas' id="basic-nav-dropdown">
                {/* Actor MODULES */}
                {renderStudentNavItems()}
              </NavDropdown>
            }
            {renderTeacherNavItems()}
            {currentUser && (currentUser.isGrader ||currentUser.isObserver || currentUser.isReviewer) &&
              <NavDropdown eventKey={24}  title='Calificacion' id="basic-nav-dropdown">
                {/* Process MODULES */}
                {renderGraderNavItems()}
                {renderReviewerNavItems()}
                <MenuItem divider></MenuItem>
                {renderObserverModuleNavItems()}
                <MenuItem divider></MenuItem>
                {renderManualesCalificacion()}
              </NavDropdown>
            }
            {currentUser && (currentUser.isAdmin ) &&
              <NavDropdown eventKey={24}  title='Reportes' id="basic-nav-dropdown">
                {/* Report MODULES */}
                {renderReportNavItems()}
              </NavDropdown>
            }
            {currentUser && (currentUser.isAdmin || currentUser.isObserverAdmin || currentUser.isExamAdmin) && <>
              <NavDropdown eventKey={24}  title='Gestion' id="basic-nav-dropdown">
                {/* ADMIN MODULES */}
                {renderAdminNavItems()}
                {renderObserverAdminModuleNavItems()}
              </NavDropdown>
            </>}
            {sessionStorage.getItem('IMPERSONATING') &&<>
              <MenuItem onClick={() => onClickUnbecome()} >
                {renderIcon(rocket_icon)} Des Personificar!!
              </MenuItem>
            </>}
            {/* <LinkContainer to="/labels" eventKey={26}>
              <MenuItem>{renderIcon(rocket_icon)} Labels</MenuItem>
            </LinkContainer>             */}
          </Nav>

          <Nav pullRight>
            {renderLoginLogout()}
          </Nav>
          <Nav pullRight>
            <NavItem className="backend-status">
                <span style={{ color: backendStatus.includes('Online') ? 'green' : 'red', marginLeft: '10px' }}>
                  {backendStatus}
                </span>
              </NavItem>
          </Nav>
        </BootstrapNavbar.Collapse>
      </BootstrapNavbar>
    )
  }

  const onClickUnbecome = () =>{
    dispatch(unbecome())
  }

  const renderAdminTools = () => {
    return (
      <>
        {sessionStorage.getItem('IMPERSONATING') &&<>
          <MenuItem onClick={() => onClickUnbecome()} >
            {renderIcon(rocket_icon)} Des Personificar!!
          </MenuItem>
        </>}  
        {renderPreferedRoles()}
        {currentUser && currentUser.toggledRoles && <>
          <MenuItem divider></MenuItem>
          <MenuItem onClick={() => onResetRoleClick()}> 
            {renderIcon(rocket_icon)} reset Roles!
          </MenuItem>
        </>}
        <MenuItem divider></MenuItem>
        {displayMenu === 'NEW' ?  <MenuItem disabled> {renderIcon(done_icon)} Menu Nuevo </MenuItem>  : <MenuItem onClick={() => setDisplayMenu('NEW')}> {renderIcon(rocket_icon)} Menu Nuevo </MenuItem>  }
        {displayMenu === 'OLD' ?  <MenuItem disabled> {renderIcon(done_icon)} Menu Viejo </MenuItem>  : <MenuItem onClick={() => setDisplayMenu('OLD')}> {renderIcon(rocket_icon)} Menu Viejo </MenuItem>  }
        <MenuItem divider></MenuItem>
        <LinkContainer to="/users" eventKey={17}>
          <MenuItem >{renderIcon(done_icon)} Usuarios</MenuItem>
        </LinkContainer> 
      </>
    )
  }

  const renderDeveloperToolsDropdown = () =>{
    const style = {
      // float:'left',
      position:'absolute'
    }
    if (currentUser && isAdmin()) {
      return (
        <div style={style}>
        <DropdownButton  id="dropdown-basic-button" title="Developer Tools" >
          {renderAdminTools()}
        </DropdownButton>
        </div>
        );
    } else {
      return (null);
    }
  }


  return (
    <>
      {displayMenu === 'NEW' && renderNavbarNew()}
      {displayMenu === 'OLD' && <Navbar /> }
      {/* {process.env.NODE_ENV === 'development' && renderAdminTools()} */}
      {process.env.NODE_ENV === 'development' && renderDeveloperToolsDropdown()}

    </>
  )
}

export default NavbarNew
