import swal from 'sweetalert2';
import { forceLogOut } from './authenticationActions';
import { current_tenant } from './tenantActions';
import axios from 'axios';

// URL base del backend.
export const ROOT_URL = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_ROOT_URL_EVALU : process.env.REACT_APP_ROOT_URL_ESPAN

// URL base del frontend 
export const hostname = window.location.host;

// Protocolo del host
export const host_protocol = window.location.protocol;

//Se debe cambiar la direccion de redireccion cuando se abran los puertos
export const LOG_IN_URL_OUTLOOK = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_LOG_IN_URL_OUTLOOK_EVALU : process.env.REACT_APP_LOG_IN_URL_OUTLOOK_ESPAN
export const LOG_IN_URL_GMAIL = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_LOG_IN_URL_GMAIL_EVALU : process.env.REACT_APP_LOG_IN_URL_GMAIL_ESPAN
export const LOG_IN_URL_YAHOO = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_LOG_IN_URL_YAHOO_EVALU : process.env.REACT_APP_LOG_IN_URL_YAHOO_ESPAN


// URL de redirección de los servicios de autenticación.
// Dependiendo del ambiente usa la URL de redirección de producción o desarrollo.
export const REDIRECT_URI = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_REDIRECT_URI_EVALU : process.env.REACT_APP_REDIRECT_URI_ESPAN


//token plataforma centro español
let blob_token =  process.env.REACT_APP_BLOB_TOKEN_ESPAN
let blob_account = process.env.REACT_APP_BLOB_ACCOUNT_ESPAN

if (current_tenant === 'ecentroevaluacion' || hostname==='centrodeevaluacion.myftp.org:8083'|| hostname==='localhost:8080' ){
  //token plataforma centro evaluacion
  blob_token = process.env.REACT_APP_BLOB_TOKEN_EVALU
  blob_account = process.env.REACT_APP_BLOB_ACCOUNT_EVALU

}


export const BLOB_ACCOUNT =blob_account
export const AZURE_STORAGE_CONNECTION_STRING= blob_token
export const AZURE_STORAGE_MULTIMEDIA_CONTAINER = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_AZURE_STORAGE_MULTIMEDIA_CONTAINER_EVALU : process.env.REACT_APP_AZURE_STORAGE_MULTIMEDIA_CONTAINER_ESPAN;
export const AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER_EVALU : process.env.REACT_APP_AZURE_STORAGE_OBSERVATIONPROCESSES_CONTAINER_ESPAN;
export const AZURE_STORAGE_FILE_CONTAINER = current_tenant === 'ecentroevaluacion' ? process.env.REACT_APP_AZURE_STORAGE_FILE_CONTAINER_EVALU : process.env.REACT_APP_AZURE_STORAGE_FILE_CONTAINER_ESPAN;

//export const SHOW_MULTIMEDIA_RECORDERS = process.env.NODE_ENV === 'production' ? false : true;
export const SHOW_MULTIMEDIA_RECORDERS = current_tenant === 'ecentroevaluacion' ? false : true;

// Código de petición con estado Prohibido.
export const FORBIDDEN_STATUS_CODE = 403;

// Código de petición con estado Entidad no procesable.
export const UNPROCESSABLE_ENTITY_STATUS_CODE = 422;

// Código de petición con estado No autorizado.
export const UNAUTHORIZED_STATUS_CODE = 401;

// Código de petición con estado Solicitud incorrecta
export const BAD_REQUEST_STATUS_CODE = 400;

// URL a información de la página principal.
export const MAIN_TEXT = `${ROOT_URL}/TextoPrincipal.html`;

// URL a información de la informacion de codificacion.
export const INFO_TEXT = `${ROOT_URL}/Infocodificacion.html`;

// URL a información de la retroalimentacion de codificacion.
export const RETRO_TEXT = `${ROOT_URL}/Retrocodificacion.html`;

// URL a manual de administrador
export const SUPPORT_ADMIN_DOC = `${ROOT_URL}/manuales/manual_administrador.pdf`;

// URL a manual de estructura
export const STRUCTURE_DOC = `/images/depgraph.svg`

// URL a manual
export const SUPPORT_DOC = `${ROOT_URL}/manual_administracion.pdf`;

// URL a manual de docentes
export const SUPPORT_TEACHER_DOC = `${ROOT_URL}/manuales/manual_docente.pdf`;

// URL a manual de codificadores
export const SUPPORT_GRADER_DOC = `${ROOT_URL}/manuales/manual_codificadores.pdf`;

// URL a manual de estudiantes
export const SUPPORT_STUDENT_DOC = `${ROOT_URL}/manuales/manual_estudiantes.pdf`;

// URL a manual de revisores
export const SUPPORT_REVIEWER_DOC = `${ROOT_URL}/manuales/manual_revisores.pdf`;

//URL al manual del gestor de procesos de observación del módulo de videos
export const OBSERVER_ADMIN_DOC = `${ROOT_URL}/manuales/Manual_Gestor_Procesos_Observacion.pdf`;

//URL al manual de observador del módulo de videos
export const OBSERVER_DOC = `${ROOT_URL}/manuales/Manual_Observador.pdf`;


// URL a la plantilla de subida de cursos profesor.
export const COURSES_TEACHER_TEMPL_DOC= `${ROOT_URL}/plantilla-curso-docente.xlsx`;

// URL a la plantilla de subida de cursos profesor.
export const COURSES_STUDENT_TEMPL_DOC= `${ROOT_URL}/plantilla-curso-docente.xlsx`;

// URL a la plantilla de subida para asignaciones de codificador.
export const COURSES_ASIGRUP_TEMPL_DOC= `${ROOT_URL}/Plantilla_Codificador.xlsx`;

// URL a la plantilla de subida para estudiantes a una ventana de aplicacíon.
export const APWINDOWS_USERS_TEMPL_DOC= `${ROOT_URL}/Plantilla_subida_de_estudiantes.xlsx`;

// URL a la plantilla de subida para estudiantes a una ventana de aplicacíon.
export const APWINDOWS_TEMPL_DOC= `${ROOT_URL}/Plantilla_subida_de_ventanas.xlsx`;

// URL a la plantilla de carga masiva de usuarios con roles
export const USR_TEMPL_DOC= `${ROOT_URL}/plantilla_carga_masiva_usuario.xlsx`;

//// URL a la plantilla de subida para estudiantes a una ventana de aplicacíon.
export const APWINDOWS_USR_DOC= `${ROOT_URL}/Plantilla_estudiante-ventana.xlsx`;

// Extensión para manejo de respuestas (elementos) sin adjunto
export const NO_FILE_EXTENSION = 'no';

// Extensiones de adjuntos aceptadas para manejo de embebidos
export const ACCEPTED_FORMATS = ['pdf', 'txt', 'html', 'webm', 'png', 'jpg', 'jpeg']

// Extensiones de adjuntos multimedia aceptadas para manejo de embebidos
export const ACCEPTED_MULTIMEDIA_FORMATS = ['mp4', 'mov','mp3']

// Cantidad de elementos para paginación
export const PAGINATION = '?page%5Bnumber%5D=1&page%5Bsize%5D=10';

// Cantidad de elementos para paginación
export const SHORT_PAGINATION = '?page%5Bnumber%5D=1&page%5Bsize%5D=5';

// Cantidad de elementos para paginación de reportes
export const REPORT_PAGINATION = '?page%5Bnumber%5D=1&page%5Bsize%5D=1';

// Cantidad de elementos para paginación de reportes
export const COURSE_REPORT_COLORS = [
  'rgba(220, 220, 220, 0.5)',
  'rgba(255, 80, 80, 0.5)',
  'rgba(255, 153, 51, 0.5)',
  'rgba(255, 255, 102, 0.5)',
  'rgba(153, 255, 102, 0.5)',
  'rgba(0, 204, 102, 0.5)',
]

export const DEFAULT_DATA_TABLE_ORDER ={
  "language": {
    "search": "Buscar: ",
    "sProcessing":    "Procesando...",
    "sLengthMenu":    "Mostrar _MENU_ registros",
    "sZeroRecords":   "No se encontraron resultados",
    "sEmptyTable":    "Ningún dato disponible en esta tabla",
    "sInfo":          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    "sInfoEmpty":     "Mostrando registros del 0 al 0 de un total de 0 registros",
    "sInfoFiltered":  "(filtrado de un total de _MAX_ registros)",
    "sInfoPostFix":   "",
    "sInfoThousands":  ",",
    "sLoadingRecords": "Cargando...",
    "oPaginate": {
        "sFirst":    "Primero",
        "sLast":    "Último",
        "sNext":    "Siguiente",
        "sPrevious": "Anterior"
    }
  },
  stateSave: false,
  ordering: true,
  paging: false,
  destroy: true
  }

/**
 * Manejador de errores de autorización.
 *
 * @export isUnauthorizedError
 * @param {*} error : Información del error a manejar.
 * @param {*} dispatch : Función para manejo de
 * @returns Si hubo o no error asociado a autorización.
 */
 export function isUnauthorizedError(error, dispatch) {
  if (error && error.request && error.request.status === UNAUTHORIZED_STATUS_CODE) {
    swal(
      'No recordamos quién eres',
      'Por favor vuelve a iniciar sesión para poder realizar esta acción',
      'error'
    );
    forceLogOut(dispatch);
    return true;
  }
  return false;
}


// Función para verificar el estado del backend
export async function checkBackendStatus() {
  try {
    const response = await axios.get(`${ROOT_URL}/health_check`);
    if (response.status === 200) {
      return 'online';  // Retorna 'online' si la respuesta es exitosa
    } else {
      return 'offline'; // Retorna 'offline' si la respuesta no es exitosa
    }
  } catch (error) {
    return 'offline'; // Retorna 'offline' si hay un error en la solicitud
  }
}



/**
 * Manejo de cabeceras de autenticación. La información se obtiene del
 * LocalStorage del navegador (token de autenticación e email).
 *
 * @export getAuthHeaders
 * @returns Objeto con los headers de autenticación asociados al usuario actual.
 */
export function getAuthHeaders() {
  return {
    headers: {
      'X-User-Token': sessionStorage.getItem('authenticationToken'),
      'X-User-Email': sessionStorage.getItem('email')
    }
  };
}
