/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { participantInfo, reasignReviewers } from "../../../actions/assignationsTestActions";
import swal from "sweetalert2";
import Loading from "../../Loading";

const ReassignReviewers = (props) => {
  const assignation_info = useSelector(state => state.assignations.assignation_answer)
  const {a_id, g_id, et_id} = props.match.params
  const dispatch = useDispatch()
  const [ loading, setLoading ] = useState(true)
  const [graders, setGraders] = useState([{}])
  const [ error, setError ] = useState('')
  const [ form, setForm ] = useState({
    newReviewer: {},
    quantity: 0
  })
  const target = "codificador"


  const styles = {
    main: {
      display: 'flex', 
      minHeight: '75vh',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
    },
    rowCenter: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row',
    },
    noMargin: {
      margin: 0
    }
  }

  const handleChange = (e) => {
    
    if (e?.target) {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    } else {
      setForm({
        ...form,
        newReviewer: e
      })
    }

    error && setError('') 
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    swal({
      title: "Atención",
      text: `Seguro que quiere reasignar ${form.quantity} revisiones a ${form.newReviewer.label}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, continuar",
      cancelButtonText: 'Cancelar'
    }).then((e)=> {
      
      if (e.value) {
        const data = {
          quantity: form.quantity,
          new_reviewer: form.newReviewer.value,
          current_reviewer: g_id
        }

        dispatch(reasignReviewers(a_id, data, ()=> {
          swal({
            text: "Reasignacion completada",
            type: "success"
          });
          props.history.goBack()
        }, (e) => {
          const message = e?.request?.response && JSON.parse(e?.request?.response)
          console.log(e.toJSON());
          
          setError(message.error ?? e.toJSON().message)
        }))
      }
    })
  }

  useEffect(()=>{
    console.log(props.match);
    dispatch(participantInfo(a_id, g_id, 2, ()=> setLoading(false), ()=> setLoading(false)))
  },[])

  useEffect(()=>{
    if (assignation_info) {
      setGraders(assignation_info.graders)
    }
  },[assignation_info])

  if (loading)
    return <Loading />

  return (<main style ={styles.main}>
    <section>
      <h1>Reasignación</h1>
      <h2>
        Reasignar revisiones disponibles del revisor: {assignation_info?.participant?.email}
      </h2>

      <p>
        A continuación, puedes reasignar las revisiones disponibles del revisor {assignation_info?.participant?.email} seleccionando un nuevo revisor e ingresando la cantidad deseada de respuestas a revisar y dando click en Guardar
      </p>
      <hr />
      <h3>
        respuestas disponibles: {assignation_info?.available - form.quantity}
      </h3>
      { !assignation_info?.available < 1 ? (
        <form id="reassignForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3 col-sm-12">
                <h4>Revisor actual</h4>
                <p>{assignation_info?.participant?.email}</p>
            </div>
            <div className="col-md-4 col-sm-12">
              <label htmlFor="participants">Revisor nuevo</label>
              <Select
                onChange={handleChange}
                value={form.newReviewer || {}}
                placeholder="Codificaciones"
                multi={false}
                options={graders} 
                required={true}
                inputId= "participants"
                id="participants"
                name="participants"
                com
              />
            </div>
            <div className="col-md-4 col-sm-12">
            <label htmlFor="quantity">Cantidad de revisiones disponibles a reasignar</label>
            <input
                  type="number"
                  min={0}
                  max={assignation_info?.available}
                  name="quantity"
                  id="quantity"
                  required
                  value={form.quantity}
                  className='form-control'
                  onChange={handleChange}
                  disabled={assignation_info?.available<1}
                />
            </div>
          </div>
        </form>
      ) : (
        <h4>No hay respuestas disponibles para asignar</h4>
      )}
    </section>
    {error && (
      <div className="alert alert-danger" role="alert">
        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span className="sr-only">Error:</span>
        {error}
      </div>
    )}
    <section 
      style={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
      }}
    >
      <button
        style={{marginRight: 'auto'}}
        onClick={() => props.history.push(`/exam-tests/${et_id}/assignations/${a_id}/editAssignation`)}
        type="button"
        className="btn btn-danger">
        Volver
      </button>
      { !assignation_info?.available < 1 &&
        <button
          type="submit"
          form="reassignForm"
          className="btn btn-info"
          disabled = {assignation_info?.available - form.quantity < 0}
        >
          Guardar
        </button>
      }
    </section>

    


  </main>)
}

export default ReassignReviewers